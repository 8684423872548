const startButton = [
  {
    text: 'Next',
    action: function () {
      this.next();
    },
  },
]

const backNextButton = [
  {
    text: 'Back',
    action: function () {
      this.back();
    },
  },
  {
    text: 'Next',
    action: function () {
      this.next();
    },
  },
]

const backCompleteButton = (store, router) => [
  {
    text: 'Back',
    action: function () {
      this.back();
    },
  },
  {
    text: 'Complete',
    action: async function () { // Tambahkan async di sini
      try {
        const dontShowAgainCheckbox = document.getElementById('dontShowAgainCheckbox');
  
        if (dontShowAgainCheckbox) {
          // hapus params
          const resolvedPath = router.asPath.split('/')[1]; 
          console.log(resolvedPath)
          const pages = {
            [resolvedPath]: true,
          };
  
          if (dontShowAgainCheckbox.checked) {
            const response = await store.user.skipTour(store.user._id, pages);
  
            if (response.status === 200) {
              this.complete();
            } else {
              console.warn('Failed to skip tour, status:', response.status);
            }
          } else {
            this.complete(); 
          }
        } else {
          console.warn('Checkbox element not found!');
          this.complete(); 
        }
      } catch (error) {
        console.error('Failed to skip tour:', error);
        this.complete();
      }
    },
  }  
];

function _pageName(pathName) {
  switch (key) {
    case value:

      break;

    default:
      break;
  }
}

const stepOfFirstAccess = (t, store, router) => {
  return [
    {
      id: 'intro',
      text: t('Welcome to SuperKos! Let me guide you through the features.'),
      buttons: [
        {
          text: 'Start',
          action: function () {
            this.next();
          },
        },
      ],
    },
    {
      text: t('Need Text'),
      attachTo: {
        element: '.landlord-form',
        on: 'right-start',
      },
      buttons: [
        {
          text: 'Back',
          action: function () {
            this.back();
          },
        },
        {
          text: 'Next',
          action: function () {
            this.next();
          },
        },
      ],
    },
    {
      text: t('Input your WhatsApp number.'),
      attachTo: {
        element: '.whatsapp-field',
        on: 'right',
      },
      // extraHighlights: [ '.landlord-form' ],
      buttons: [
        {
          text: 'Back',
          action: function () {
            this.back();
          },
        },
        {
          text: 'Next',
          action: function () {
            this.next();
          },
        },
      ],
    },
    {
      text: t('Input your Address.'),
      attachTo: {
        element: '.address-field',
        on: 'right',
      },
      buttons: [
        {
          text: 'Back',
          action: function () {
            this.back();
          },
        },
        {
          text: 'Next',
          action: function () {
            this.next();
          },
        },
      ],
    },
    {
      text: t('Input your Reference.'),
      attachTo: {
        element: '.reference-field',
        on: 'right',
      },
      buttons: [
        {
          text: 'Back',
          action: function () {
            this.back();
          },
        },
        {
          text: 'Next',
          action: function () {
            this.next();
          },
        },
      ],
    },
    {
      text: t('Save.'),
      attachTo: {
        element: '.submit-button',
        on: 'right',
      },
      buttons: [
        {
          text: 'Back',
          action: function () {
            this.back();
          },
        },
        {
          text: 'Next',
          action: function () {
            this.complete();
          },
        },
      ],
    },
  ];
};

const emptyDashboardGuide = (t, store, router) => {
  return [
    // TODO: Fill this with required step
    {
      id: 'intro',
      text: t('Welcome to SuperKos! Let me guide you through the features.'),
      buttons: [
        {
          text: 'Start',
          action: function () {
            this.next();
          },
        },
      ],
    },
    {
      text: t('Click here to add new property.'),
      attachTo: {
        element: '.shepherd-shortcutAddProperty',
        on: 'left',
      },
      buttons: [
        {
          text: 'Back',
          action: function () {
            this.back();
          },
        },
        {
          text: 'Next',
          action: function () {
            this.next();
          },
        },
      ],
    },
  ]
};

const filledDashboardGuide = (t, store, router) => {
  return [
    // TODO: Fill this with required step
  ]
};

const propertiesGuide = (t, store, router) => {
  return [
    {
      id: 'intro',
      text: t('Welcome to Property Page! This guide will explain the features of this page.'),
      attachTo: {
        element: '.shepherd-currentPage',
        on: 'right-start'
      },
      buttons: startButton,
    },
    {
      text: t('Click this button to add new property.'),
      attachTo: {
        element: '.shepherd-propertyList-addPropertyButton',
        on: 'left',
      },
      buttons: backNextButton,
    },
    {
      text: t('Use this searchBar to find property.'),
      attachTo: {
        element: '.shepherd-propertyList-searchBar',
        on: 'right',
      },
      // buttons: backNextButton,
      buttons: backCompleteButton(store, router),
    },
    // TODO: Add below steps when properties length > 0
    // {
    //   text: t('Click here to view and edit property detail.'),
    //   attachTo: {
    //     element: '.shepherd-propertyList-editPropertyButton-0',
    //     on: 'bottom-start',
    //   },
    //   // extraHighlights: [ '.shepherd-propertyList-propertyCard-0' ],
    //   buttons: backNextButton,
    // },
    // {
    //   text: t('Click here to add rooms on this property.'),
    //   attachTo: {
    //     element: '.shepherd-propertyList-addRoomsButton-0',
    //     on: 'bottom-start',
    //   },
    //   // extraHighlights: [ '.shepherd-propertyList-propertyCard-0' ],
    //   buttons: backCompleteButton(store, router),
    // },
  ]
};

const propertyDetailGuide = (t, store, router) => {
  return [
    {
      id: 'intro',
      text: t('Welcome to Property Detail Page! This guide will explain the features of this page.'),
      buttons: startButton,
    },
    {
      text: t('Click this tab to view or edit property information.'),
      attachTo: {
        element: '.shepherd-editPropertyTab',
        on: 'bottom-start',
      },
      buttons: backNextButton,
    },
    {
      text: t('Click this tab to view or edit property transfer details.'),
      attachTo: {
        element: '.shepherd-transferDetailTab',
        on: 'bottom-start',
      },
      buttons: backNextButton,
    },
    {
      text: t('Click this tab to view or edit property services.'),
      attachTo: {
        element: '.shepherd-serviceListTab',
        on: 'bottom-start',
      },
      buttons: backNextButton,
    },
    {
      text: t('Click this tab to view or edit property rooms.'),
      attachTo: {
        element: '.shepherd-roomListTab',
        on: 'bottom-start',
      },
      buttons: backNextButton,
    },
    {
      text: t('Click this tab to view or edit property invoice template.'),
      attachTo: {
        element: '.shepherd-invoiceTemplateTab',
        on: 'bottom-start',
      },
      buttons: backNextButton,
    },
    {
      text: t('Click this tab to view or edit property landing page.'),
      attachTo: {
        element: '.shepherd-landingPageTab',
        on: 'bottom-start',
      },
      buttons: backNextButton,
    },
    {
      text: t('Click this button to add new service for this property.'),
      attachTo: {
        element: '.shepherd-propertyDetail-addServiceButton',
        on: 'bottom-start',
      },
      buttons: backNextButton,
    },
    {
      text: t('Click this button to delete this property.'),
      attachTo: {
        element: '.shepherd-propertyDetail-deleteButton',
        on: 'bottom-start',
      },
      buttons: backCompleteButton(store, router),
    },
  ]
};

const roomsGuide = (t, store, router) => {
  return [
    {
      id: 'intro',
      text: t('Welcome to Room Page! This guide will explain the features of this page.'),
      attachTo: {
        element: '.shepherd-currentPage',
        on: 'right-start'
      },
      buttons: [
        {
          text: 'Start',
          action: function () {
            this.next();
          },
        },
      ],
    },
    {
      text: t('Click this button to add new room.'),
      attachTo: {
        element: '.shepherd-roomList-addRoomButton',
        on: 'bottom-start',
      },
      buttons: backNextButton,
    },
    {
      text: t('Use this searchBar to find room.'),
      attachTo: {
        element: '.shepherd-searchBar',
        on: 'bottom-start',
      },
      buttons: backNextButton,
    },
    {
      text: t('Use this filter status button to find room by status.'),
      attachTo: {
        element: '.shepherd-toggleStatusMenu',
        on: 'top-start',
      },
      extraHighlights: ['.shepherd-statusValue'],
      buttons: backNextButton,
    },
    {
      text: t('Use this filter property menu to find room by property.'),
      attachTo: {
        element: '.shepherd-filterPropertyMenu',
        on: 'bottom-start',
      },
      buttons: backNextButton,
    },
    {
      text: t('Click this tab to switch the display options, grid view or list view.'),
      attachTo: {
        element: '.shepherd-roomList-tabsView',
        on: 'bottom-end',
      },
      buttons: backNextButton,
    },
    {
      text: t('Click this tab to view room list.'),
      attachTo: {
        element: '.shepherd-roomList-roomsTab',
        on: 'bottom-start',
      },
      buttons: backNextButton,
    },
    // {
    //   text: t('Click here to view and edit room detail.'),
    //   attachTo: {
    //     element: '.shepherd-roomList-roomCard-0',
    //     on: 'right-start',
    //   },
    //   buttons: backNextButton,
    // },
    {
      text: t('Click this tab to view room schedule.'),
      attachTo: {
        element: '.shepherd-roomList-roomsScheduleTab',
        on: 'bottom-start',
      },
      buttons: backCompleteButton(store, router),
    },
  ]
};

const roomDetailGuide = (t, store, router) => {
  return [
    {
      id: 'intro',
      text: t('Welcome to Room Detail Page! This guide will explain the features of this page.'),
      buttons: [
        {
          text: 'Start',
          action: function () {
            this.next();
          },
        },
      ],
    },
    {
      text: t('Click this tab to view or edit room information.'),
      attachTo: {
        element: '.shepherd-roomDetail-roomDetailTab',
        on: 'right-start',
      },
      buttons: backNextButton,
    },
    {
      text: t('Click this tab to view, add or edit room expenses.'),
      attachTo: {
        element: '.shepherd-roomDetail-roomExpensesTab',
        on: 'right-start',
      },
      buttons: backNextButton,
    },
    {
      text: t('Here is the property information of this room.'),
      attachTo: {
        element: '.shepherd-roomDetail-propertyInfo',
        on: 'left-start',
      },
      extraHighlights: ['.shepherd-dashboardCard-title'],
      buttons: backNextButton,
    },
    {
      text: t('Here is list of tenants who have stayed in this room until now.'),
      attachTo: {
        element: '.shepherd-roomDetail-occupancyHistory',
        on: 'left-start',
      },
      extraHighlights: ['.shepherd-dashboardCard-title'],
      buttons: backNextButton,
    },
    {
      text: t('Click this button to delete this room.'),
      attachTo: {
        element: '.shepherd-roomDetail-deleteRoomButton',
        on: 'left-start',
      },
      buttons: backCompleteButton(store, router),
    },
  ]
};

export const expenseGuide = (t, store, router) => {
  return [
    {
      id: 'intro',
      text: t('Welcome to the Expense Page! Let me walk you through its features.'),
      buttons: [
        {
          text: 'Start',
          action: function () {
            this.next();
          },
        },
      ],
    },
    {
      text: t('Click this button to add a new expense.'),
      attachTo: {
        element: '.shepherd-expense-new-button',
        on: 'left',
      },
      buttons: backNextButton,
    },
    {
      text: t('Use this button to export expenses to Excel.'),
      attachTo: {
        element: '.shepherd-expense-export-button',
        on: 'left',
      },
      buttons: backNextButton,
    },
    {
      text: t('Here, you can filter expenses by year, property, month, category, and room.'),
      attachTo: {
        element: '.shepherd-expense-filter',
        on: 'bottom',
      },
      buttons: backNextButton,
    },
    {
      text: t('This section displays the expense graph for better insights.'),
      attachTo: {
        element: '.shepherd-expense-graph',
        on: 'bottom',
      },
      buttons: backNextButton,
    },
    {
      text: t('Below, you will find a detailed list of your expenses.'),
      attachTo: {
        element: '.shepherd-expense-card-list',
        on: 'top',
      },
      buttons: backCompleteButton(store, router),
    },
  ]
};

// TENANTS
export const tenantsGuide = (t, store, router) => {
  return [
    {
      id: 'intro',
      text: t('Welcome to Tenant Page! Let me guide you through the features.'),
      buttons: [
        {
          text: 'Start',
          action: function () {
            this.next();
          },
        },
      ],
    },
    {
      text: t('Click this button to add new tenant.'),
      attachTo: {
        element: '.shepherd-tenantList-addRoomButton',
        on: 'bottom-start',
      },
      buttons: [
        {
          text: 'Back',
          action: function () {
            this.back();
          },
        },
        {
          text: 'Next',
          action: function () {
            const createButton = document.querySelector('.shepherd-tenantList-addRoomButton'); // Cari tombol dengan class .x
            createButton.click(); 
      
            setTimeout(() => {
              this.next();
            }, 100);
          },
        },
      ],
    },
    {
      text: t('And then, Click this button to save.'),
      attachTo: {
        element: '.shepherd-tenantList-addTenant',
        on: 'bottom-start',
      },
      buttons: [
        {
          text: 'Back',
          action: function () {
            const okButton = document.querySelector('.shepherd-tenantList-close');
            okButton.click();
      
            setTimeout(() => {
              this.back();
            }, 100);
          },
        },
        {
          text: 'Next',
          action: function () {
            const okButton = document.querySelector('.shepherd-tenantList-close');
            okButton.click();
      
            setTimeout(() => {
              this.next();
            }, 100);
          },
        },
      ],
    },
    {
      text: t('Use this to filter tenants by tenant name.'),
      attachTo: {
        element: '.shepherd-searchBar',
        on: 'bottom-start',
      },
      buttons: [
        {
          text: 'Back',
          action: function () {
            const createButton = document.querySelector('.shepherd-tenantList-addRoomButton'); // Cari tombol dengan class .x
            createButton.click(); 
      
            setTimeout(() => {
              this.back();
            }, 100);
          },
        },
        {
          text: 'Next',
          action: function () {
            this.next();
          },
        },
      ],
    },
    {
      text: t('Use this to filter tenants by status.'),
      attachTo: {
        element: '.shepherd-toggleStatusMenu',
        on: 'bottom-start',
      },
      buttons: backNextButton,
    },
    {
      text: t('Use this to filter tenants by property.'),
      attachTo: {
        element: '.shepherd-filterPropertyMenu',
        on: 'bottom-start',
      },
      buttons: backNextButton,
    },
    {
      text: t('Click here to change tenant list by grid or tab.'),
      attachTo: {
        element: '.shepherd-tenantList-tabsView',
        on: 'bottom-end',
      },
      // extraHighlights: [ '.shepherd-propertyList-propertyCard-0' ],
      buttons: backCompleteButton(store, router),
    },
  ]
};

export const tenantBioStepperGuide = (t, store, router) => {
  return [
    {
      text: t("Enter the tenant's full name (e.g., John Doe)."),
      attachTo: {
        element: '.tenant-stepper-bio-name',
        on: 'bottom-start',
      },
      buttons: [
        {
          text: 'Start',
          action: function () {
            this.next();
          },
        },
      ],
    },
    {
      text: t('Select the appropriate gender (e.g., Male or Female).'),
      attachTo: {
        element: '.tenant-stepper-bio-gender',
        on: 'bottom-start',
      },
      buttons: backNextButton,
    },
    {
      text: t('Choose the current marital status (e.g., Single, Married, Divorced).'),
      attachTo: {
        element: '.tenant-stepper-bio-marital',
        on: 'bottom-start',
      },
      buttons: backNextButton,
    },
    {
      text: t("Fill in the tenant's profession or job title (e.g., Software Engineer, Teacher)."),
      attachTo: {
        element: '.tenant-stepper-bio-job',
        on: 'bottom-start',
      },
      buttons: backNextButton,
    },
    {
      text: t("If applicable, enter the tenant's vehicle registration number."),
      attachTo: {
        element: '.tenant-stepper-bio-vehicle',
        on: 'bottom-start',
      },
      buttons: backNextButton,
    },
    {
      text: t("Provide the tenant's official identification number (e.g., ID card number or passport number)."),
      attachTo: {
        element: '.tenant-stepper-bio-id',
        on: 'bottom-end',
      },
      buttons: backNextButton,
    },
    {
      text: t("Add any special remarks or notes for the tenant’s entry (e.g., special needs or conditions)."),
      attachTo: {
        element: '.tenant-stepper-bio-notes',
        on: 'bottom-end',
      },
      buttons: backNextButton,
    },
    {
      text: t("Enter the tenant's contact information: Name, Email, Whatsapp Number"),
      attachTo: {
        element: '.tenant-stepper-bio-contact',
        on: 'bottom-end',
      },
      buttons: backNextButton,
    },
    {
      text: t(`Once all fields are filled, click the SAVE button to submit the form.`),
      attachTo: {
        element: '.tenant-stepper-bio-save',
        on: 'bottom-end',
      },
      buttons: backCompleteButton(store, router),
    },
  ]
};

export const tenantRentStepperGuide = (t, store, router) => {
  return [
    {
      text: t("Select the start date for the rental period."),
      attachTo: {
        element: '.tenant-stepper-rent-start',
        on: 'bottom-start',
      },
      buttons: [
        {
          text: 'Start',
          action: function () {
            this.next();
          },
        },
      ],
    },
    {
      text: t('Select the specific room you are renting from the dropdown list.'),
      attachTo: {
        element: '.tenant-stepper-rent-room',
        on: 'bottom-start',
      },
      buttons: backNextButton,
    },
    {
      text: t('Specify the rental period, such as daily, monthly, yearly, or a custom duration.'),
      attachTo: {
        element: '.tenant-stepper-rent-period',
        on: 'top-start',
      },
      buttons: backNextButton,
    },
    {
      text: t('Review the rental price. The price is displayed as Rp 0 by default, but it will update based on the room and period selection.'),
      attachTo: {
        element: '.tenant-stepper-rent-price',
        on: 'top-start',
      },
      buttons: backNextButton,
    },
    {
      text: t("Confirm the security deposit (Uang Jaminan) amount. This value is often pre-filled."),
      attachTo: {
        element: '.tenant-stepper-rent-depo',
        on: 'top-start',
      },
      buttons: backNextButton,
    },
    {
      text: t("Verify the Down Payment (Uang Muka) amount, which is essential to secure the room."),
      attachTo: {
        element: '.tenant-stepper-rent-dp',
        on: 'top-start',
      },
      buttons: backNextButton,
    },
    {
      text: t(`Use the "Lunas" checkbox to mark the rental payment or down payment as fully paid.`),
      attachTo: {
        element: '.tenant-stepper-rent-paid',
        on: 'top-end',
      },
      buttons: backNextButton,
    },
    {
      text: t("After filling out all fields and confirming the details, click the yellow “SIMPAN” button to save the form."),
      attachTo: {
        element: '.tenant-stepper-rent-save',
        on: 'bottom-end',
      },
      buttons: backCompleteButton(store, router),
    },
  ]
};

export const tenantDocStepperGuide = (t, store, router) => {
  return [
    {
      text: t("Upload important documents for the tenant, such as an ID card (KTP), passport, or other required files."),
      attachTo: {
        element: '.tenant-stepper-doc-upload',
        on: 'bottom-start',
      },
      buttons: [
        {
          text: 'Start',
          action: function () {
            this.next();
          },
        },
      ],
    },
    {
      text: t('Once the documents are uploaded, they will appear in the document list area.'),
      attachTo: {
        element: '.tenant-stepper-doc-list',
        on: 'bottom-start',
      },
      buttons: backNextButton,
    },
    {
      text: t('After uploading and verifying the tenant documents, click the “SIMPAN” button to save and submit the information.'),
      attachTo: {
        element: '.tenant-stepper-doc-save',
        on: 'top-start',
      },
      buttons: backCompleteButton(store, router),
    },
  ]
};

export const TenantDetailGuide = (t, store, router) => {
  return [
    {
      id: 'intro',
      text: t('Welcome to tenant Page! Let me guide you through the features.'),
      buttons: [
        {
          text: 'Start',
          action: function () {
            this.next();
          },
        },
      ],
    },
    {
      text: t('Click this button to add new bill.'),
      attachTo: {
        element: '.shepherd-tenant-add-bill',
        on: 'left',
      },
      buttons: backNextButton,
    },
    {
      text: t('Click this button to end rent tenant'),
      attachTo: {
        element: '.shepherd-tenant-end-rent',
        on: 'left',
      },
      buttons: backNextButton,
    },
    {
      text: t('Click this button to edit tenant information and rent'),
      attachTo: {
        element: '.shepherd-tenant-edit',
        on: 'left',
      },
      buttons: backNextButton,
    },
    {
      text: t('This tab showing tenant profil'),
      attachTo: {
        element: '.shepherd-tenant-tab-profil',
        on: 'bottom-start',
      },
      buttons: backNextButton,
    },
    {
      text: t('This tab showing tenant rent'),
      attachTo: {
        element: '.shepherd-tenant-tab-rent',
        on: 'bottom-start',
      },
      // extraHighlights: [ '.shepherd-propertyList-propertyCard-0' ],
      buttons: backNextButton,
    },
    {
      text: t('This tab showing tenant invoice'),
      attachTo: {
        element: '.shepherd-tenant-tab-invoice',
        on: 'bottom-start',
      },
      // extraHighlights: [ '.shepherd-propertyList-propertyCard-0' ],
      buttons: backNextButton,
    },
    {
      text: t('This tab showing tenant document'),
      attachTo: {
        element: '.shepherd-tenant-tab-doc',
        on: 'bottom-start',
      },
      // extraHighlights: [ '.shepherd-propertyList-propertyCard-0' ],
      buttons: backCompleteButton(store, router),
    },
  ]
};

export const buildStepsGuide = (page, t, store, router) => {
  switch (page) {
    case '/firstaccess':
      return {
        stepName: '/firstaccess',
        stepList: stepOfFirstAccess(t, store, router)
      };
    case '/properties':
      return {
        stepName: '/properties',
        stepList: propertiesGuide(t, store, router)
      };
    case '/properties/[...param]':
      return {
        stepName: '/properties/[...param]',
        stepList: propertyDetailGuide(t, store, router)
      };
    case '/rooms':
      return {
        stepName: '/rooms',
        stepList: roomsGuide(t, store, router)
      };
    case '/rooms/[...param]':
      return {
        stepName: '/rooms/[...param]',
        stepList: roomDetailGuide(t, store, router)
      };
    case '/tenants':
      return {
        stepName: '/tenants',
        stepList: tenantsGuide(t, store, router)
      };
    

    // TODO: Need to create default steps
    default:
      return {
        stepName: null,
        stepList: null
      };
  }
}
