import { action, computed, flow, makeObservable, observable, reaction } from 'mobx';
import moment from 'moment';
import api from '../utils/apiService';

import { apiFetcher } from '../utils/fetch';

export default class Expense {
  constructor() {
    this.data = [];
    this.dataRecurring = {};
    this.dataAllFilter = {};
    this.filters = { year: moment().year(), month: moment().month() + 1, propertyId: "", category: "", roomId: "" };
    this.selected = {};
    this.selectedExpense = [];
    
    makeObservable(this, {
      data: observable,
      dataRecurring: observable,
      dataAllFilter: observable,
      selectedExpense: observable,
      filters: observable,
      selected: observable,
      setFilters: action,
      setSelected: action,
      fetchWithoutProperty: flow,
      fetchRecurring: flow,
      fetchAllWithFilter: flow,
      deleteRecurring: flow,
      stopRecurring: flow,
      sameById: flow,
      delete: flow,
      create: flow,
      update: flow,
      updateAll: flow,
    });

    reaction(
      () => this.filters, // Hanya memantau perubahan pada filters
      () => {
        this.fetchWithoutProperty();
        this.fetchRecurring();
        this.fetchAllWithFilter();
      }
    );
  }

  setSelected = (expense) => (this.selected = expense);
  setSelectedExpense = (expense) => (this.selectedExpense = expense);

  setFilters = ({ year = "", month = "", propertyId = "", category = "", roomId = "" }) =>
    (this.filters = { year, month, propertyId, category, roomId });


  *fetchWithoutProperty() {
    try {    
      const response = yield api.get(`/expense/${this.filters.year}`);
      this.data = response.data;

      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *fetchRecurring() {
    try {
      const { year, month, propertyId, category, roomId } = this.filters;
  
      const response = yield api.get(`/expense/recurring/${year}`, {
        params: {
          propertyId,
          category,
          month,
          roomId,
        },
      });
  
      this.dataRecurring = response.data;
  
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }
  

  *fetchAllWithFilter() {
    try {
      const { year, month, propertyId, category, roomId } = this.filters;
  
      const response = yield api.get(`/expense/filter/${year}`, {
        params: {
          propertyId,
          category,
          month,
          roomId,
        },
      });
  
      this.dataAllFilter = response.data;
  
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *create(expense) {
    try {
      const response = yield api.post(`/expense`, expense);

      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *update(id, expense) {
    try {
      const response = yield api.patch(`/expense/${id}`, expense);

      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *updateAll(id, expense) {
    try {
      const response = yield api.patch(`/expense/recurring/${id}`, expense);

      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *delete(id) {
    try {
      const response = yield api.delete(`/expense/${id}`);
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *deleteRecurring(id) {
    try {
      const response = yield api.delete(`/expense/deleteReccuring/${id}`);
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *stopRecurring(id) {
    try {
      const response = yield api.patch(`/expense/stop/${id}`);
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }
  
  *sameById(id) {
    try {
      const response = yield api.get(`/expense/sameById/${id}`);
      if (Array.isArray(response.data)) {
        this.setSelectedExpense(response.data);
      } else {
        console.warn("Expected array but received:", response.data);
        this.setSelectedExpense([]);
      }
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }
}
