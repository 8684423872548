import { offset } from "@floating-ui/dom";
import { action, flow, makeObservable, observable } from "mobx";
import Shepherd from "shepherd.js";
import 'shepherd.js/dist/css/shepherd.css';
import { title } from "../styles/styles";
import api from "../utils/apiService";

export default class GuideTour {
  constructor() {
    this.isTourActive = false;
    this.steps = []; // array of stepTour
    this.tour = null;

    makeObservable(this, {
      isTourActive: observable,
      steps: observable,
      setSteps: action,
      startTour: action,
      endTour: action,
      setupTour: action,
    });
  }

  // TODO: 
  setSteps(newSteps) {
    this.steps = newSteps;
  }

  setupTour(newSteps) {
    this.tour = new Shepherd.Tour({
      defaultStepOptions: {
        cancelIcon: {
          enabled: true
        },
        classes: 'shepherd-theme-arrows',
        scrollToHandler: (element) => {
          if (element) {
            const offset = 300;
            const elementPosition = element.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({
              top: elementPosition - offset,
              behavior: 'smooth',
            });
          } else {
            console.warn('Target element not found for scrolling.');
          }
        },
        floatingUIOptions: {
          middleware: [offset(10)]
        },
      },
      useModalOverlay: true,
    });

    // show steps
    const stepsWithCounter = newSteps.map((step, index) => {
      const totalSteps = newSteps.length;
      const isLastStep = index + 1 === totalSteps;

      return {
        ...step,
        text : `
        <div style="margin-bottom:10px">
          ${step.text || ''}
        </div>
        ${isLastStep ? `
          <label style="display: block; margin-top: 10px;">
            <input type="checkbox" id="dontShowAgainCheckbox" />
            Don't show this again
          </label>
        ` : ''}
        `,
        title: `
          <div style="font-weight: light; opacity:0.5; margin-top: 10px;">
            ${index + 1}/${totalSteps}
          </div>
        `,
      };
    });

    // Add steps
    this.tour.addSteps(stepsWithCounter);
  }

  setSteps(newSteps) {
    this.steps = newSteps;
  }

  startTour() {
    this.isTourActive = true;
    this.tour?.start();
  }

  endTour() {
    this.isTourActive = false;
    this.tour?.cancel();
  }

}
