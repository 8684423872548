import { Badge, withStyles } from "@material-ui/core";
import { color } from "../styles/color";

export const formatDateTime = (timestamp) => {
  // Convert to a JavaScript Date object
  const date = new Date(timestamp);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true, // Ensures AM/PM formatting
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, // Local timezone
  };

  const formattedDate = date.toLocaleString(undefined, options);
  return formattedDate;
};

// Utility to format the time for chat view
export const formatChatTime = (timestamp) => {
  const date = new Date(timestamp); // Convert to Date object
  const now = new Date();
  const diffInMinutes = Math.floor((now - date) / (1000 * 60)); // Difference in minutes

  if (diffInMinutes < 1) {
    return "Just now";
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes} minutes ago`;
  } else if (diffInMinutes < 24 * 60) {
    // If the message is from today, show time (e.g., "12:34 PM")
    return date.toLocaleTimeString(undefined, {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  } else {
    // If the message is from a previous day, show the full date (e.g., "Jan 19, 2025")
    return date.toLocaleDateString(undefined, {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  }
};

export const capitalizeFirstLetterOnly = (text) => {
  if (!text) return "";
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

export const CustomBadge = withStyles(() => ({
  badge: {
    backgroundColor: color?.highLightColor,
    fontWeight: 600,
  },
}))(Badge);

export const NOTIFICATIN_TYPE = {
  INVOICE: "invoice",
  RENT_REMINDER: "rentcall",
  RENT_REMINDER_NOTIFICATION: "rentcall_reminder",
  RENT_LAST_REMINDER: "rentcall_last_reminder",
  PAYMENT_RECEIPT: "payment_receipt",
  PAYMENT_CONFIRMATION: "payment_notification",
  DAILY_DUE_REPORT: "daily_due_report",
  WEEKLY_DUE_REPORT: "weekly_due_report",
  QR_DISCONNECTED: "qr_disconnected",
};

export const ADMIN_PHONE = {
  ADMIN_PHONE_NUMBER: "918770497477",
};

export const isObjectEmpty=(obj)=> {
  return Object.keys(obj).length === 0;
}