import { Box, Divider, Typography } from '@material-ui/core';

import { SwitchField } from './SwitchField';

export function Section({
  label,
  subtitle,
  description,
  visible = true,
  withSwitch = false,
  switchName,
  children,
  className
}) {
  return (
    <Box className={className} pb={4}>
      {visible ? (
        <>
          <Box display="flex" /*justifyContent="space-between"*/ alignItems="center" flexWrap="wrap" gridGap={8}>
            <Typography variant="h5">{label}</Typography>
            {subtitle ? (
              <Box>
                <Typography variant="body2" color="textSecondary">{!!subtitle && subtitle}</Typography>
              </Box>
            ) : null}
            {/* {withSwitch ? (
              <SwitchField name={switchName} color="primary" />
            ) : null} */}
          </Box>

          <Box fontSize="body2.fontSize" color="text.secondary">
            {!!description && description}
          </Box>

          <Box pt={1} pb={2}>
            <Divider />
          </Box>
          {children}
        </>
      ) : (
        children
      )}
    </Box>
  );
}
