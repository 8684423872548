import * as jose from "jose";
import { action, computed, flow, makeObservable, observable } from "mobx";
import {
  apiFetcher,
  authApiFetcher,
  getAccessToken,
  setAccessToken,
} from "../utils/fetch";
import { updateItems } from "./utils";
import { isServer } from "@microrealestate/commonui/utils";

export const ADMIN_ROLE = "administrator";
export const SUPERADMIN_ROLE = "super admin";
export const LANDLORD_ROLE = "landlord";
export const RENTER_ROLE = "renter";
export const ROLES = [ADMIN_ROLE, SUPERADMIN_ROLE];
import api from "../utils/apiService";
import { makePersistable } from "mobx-persist-store";
import config from "../config";
import Cookies from "js-cookie";

export default class User {
  constructor() {
    this.selected = {};
    this.items = [];
    this.token = undefined;
    this.tokenExpiry = undefined;
    this._id = undefined;
    this.fullName = undefined;
    this.email = undefined;
    this.role = undefined;
    this.scopeProperty = undefined;
    this.plan = undefined;
    this.trialEndDate = undefined;
    this.isSkipTourGuide = {};

    makeObservable(this, {
      items: observable,
      selected: observable,
      token: observable,
      tokenExpiry: observable,
      _id: observable,
      fullName: observable,
      email: observable,
      role: observable,
      plan: observable,
      trialEndDate: observable,
      isSkipTourGuide: observable,
      signedIn: computed,
      isAdministrator: computed,
      isLandlord: computed,
      isSuperAdmin: computed,
      setRole: action,
      setUserFromToken: action,
      setSelected: action,
      setItems: action,
      signUp: flow,
      createAdmin: flow,
      fetch: flow,
      fetchUser: flow,
      updateRealmUser: flow,
      updateUser: flow,
      signIn: flow,
      saveDeviceToken: flow,
      signInGoogle: flow,
      signOut: flow,
      removeAdmin: flow,
      refreshTokens: flow,
      forgotPassword: flow,
      resetPassword: flow,
      skipTour: flow,
      fetchNotifications: flow,
      markAllNotification: flow,
      removeDeviceEntry: flow,
    });

    makePersistable(this, {
      name: "User",
      properties: [
        "_id",
        "isSkipTourGuide",
        "role",
        "fullName",
        "email",
        "plan",
        "trialEndDate",
      ],
      storage: typeof window !== "undefined" ? window.localStorage : undefined,
      expireIn: 86400000, // One day in milliseconds
      removeOnExpiration: true,
    });
  }

  get signedIn() {
    let token = getAccessToken();
    return token ? true : false;
  }

  get isAdministrator() {
    return this.role === ADMIN_ROLE;
  }

  get isLandlord() {
    return this.role === LANDLORD_ROLE;
  }

  get isSuperAdmin() {
    return this.role === SUPERADMIN_ROLE;
  }

  setSelected = (user) => (this.selected = user);
  setItems = (user) => {
    this.items = updateItems(user, this.items);
  };

  setRole(role) {
    this.role = role;
  }

  setUserFromToken(accessToken) {
    const {
      account: {
        _id,
        fullName,
        email,
        plan,
        scopeProperty,
        trialEndDate,
        isSkipTourGuide,
      },
      exp,
    } = jose.decodeJwt(accessToken);
    this._id = _id;
    this.fullName = fullName ?? "";
    this.email = email;
    this.plan = plan;
    this.scopeProperty = scopeProperty;
    this.trialEndDate = trialEndDate;
    this.token = accessToken;
    this.tokenExpiry = exp;
    this.isSkipTourGuide = isSkipTourGuide;
    setAccessToken(accessToken);
  }

  *signUp(fullName, email, password) {
    try {
      yield api.post("/authenticator/signup", {
        fullName,
        email,
        password,
      });
      return 200;
    } catch (error) {
      return error.response.status;
    }
  }

  *createAdmin(fullName, email, role, scopeProperty, realmId) {
    try {
      const response = yield api.post("/authenticator/signup/admin", {
        fullName,
        email,
        role,
        scopeProperty,
        realmId,
      });
      return { status: 200, data: response.data };
    } catch (error) {
      return error.response.status;
    }
  }

  *fetch(realmId) {
    try {
      const response = yield api.get(`/authenticator/user/filter/${realmId}`);
      this.items = response.data;

      return { status: 200, data: response.data };
    } catch (error) {
      return error.response.status;
    }
  }

  *fetchUser(userId) {
    try {
      const response = yield api.get(`/authenticator/user/${userId}`);
      return { status: 200, data: response.data };
    } catch (error) {
      return error.response.status;
    }
  }

  *updateRealmUser(email, realmId) {
    try {
      const response = yield api.patch("/authenticator/user/addRealm", {
        email,
        realmId,
      });
      return { status: 200, data: response.data };
    } catch (error) {
      return error.response.status;
    }
  }

  *updateUser(_id, email, fullName, role, isEnabled, scopeProperty) {
    try {
      const response = yield api.patch("/authenticator/user/update", {
        _id,
        email,
        fullName,
        role,
        isEnabled,
        scopeProperty,
      });
      return { status: 200, data: response.data };
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  *removeAdmin(_id) {
    try {
      const response = yield api.patch(`/authenticator/user/${_id}`);
      return { status: 200, data: response.data };
    } catch (error) {
      console.error(error);
      return error;
    }
  }
  *saveDeviceToken(email, deviceToken, deviceType) {
    try {
      const response = yield api.post(`/notification/save-device-token`, {
        email,
        deviceToken,
        deviceType,
      });
      return { status: 200, data: response.data };
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  *signIn(email, password, deviceToken) {
    try {
      const response = yield api.post("/authenticator/signin", {
        email,
        password,
        deviceToken,
      });
      const { accessToken } = response.data;
      this.setUserFromToken(accessToken);
      return {
        status: 200,
      };
    } catch (error) {
      console.error(error);
      // return error.response.status;
      return {
        status: error.response.status,
        data: error.response.data ? error.response.data : error.response,
      };
    }
  }

  *signInGoogle(token) {
    try {
      const response = yield api.post("/authenticator/socialLogin", {
        access_token: token,
      });
      if (response.status === 200) {
        const { accessToken } = response.data;
        this.setUserFromToken(accessToken);
        return {
          status: 200,
        };
      } else {
        return {
          status: response.status,
        };
      }
    } catch (error) {
      console.error(error);
      // return error.response.status;
      return {
        status: error.response.status,
        data: error.response.data ? error.response.data : error.response,
      };
    }
  }

  *signOut() {
    try {
      yield api.delete("/authenticator/signout");
    } finally {
      this._id = null;
      this.fullName = null;
      this.email = null;
      this.token = null;
      this.tokenExpiry = undefined;
      setAccessToken(null);
      Cookies.remove(config.ACCESS_TOKEN_KEY);
      Cookies.remove(config.ORG_KEY);
    }
  }

  *removeDeviceEntry(email) {
    try {
      yield api.delete("/authenticator/removeDeviceEntry", {
        email,
      });
      return 200;
    } catch (error) {
      console.error(error);
      return error.response.status;
    }
  }

  *refreshTokens(context) {
    try {
      let response;
      // request to get the new tokens
      if (isServer()) {
        const authFetchApi = authApiFetcher(context.req.headers.cookie);
        response = yield authFetchApi.post("/authenticator/refreshtoken");
        const cookies = response.headers["set-cookie"];
        if (cookies) {
          context.res.setHeader("Set-Cookie", cookies);
        }
      } else {
        response = yield api.post("/authenticator/refreshtoken");
      }

      // set access token in store
      if (response?.data?.accessToken) {
        const { accessToken } = response.data;
        this.setUserFromToken(accessToken);
        return { status: 200 };
      } else {
        this._id = undefined;
        this.fullName = undefined;
        this.email = undefined;
        this.token = undefined;
        this.tokenExpiry = undefined;
        setAccessToken(null);
      }
    } catch (error) {
      this._id = undefined;
      this.fullName = undefined;
      this.email = undefined;
      this.token = undefined;
      this.tokenExpiry = undefined;
      setAccessToken(null);
      console.error(error);
      return { status: error?.response?.status, error };
    }
  }

  *forgotPassword(email) {
    try {
      yield api.post("/authenticator/forgotpassword", {
        email,
      });
      return 200;
    } catch (error) {
      console.error(error);
      return error.response.status;
    }
  }

  *resetPassword(resetToken, password) {
    try {
      yield api.patch("/authenticator/resetpassword", {
        resetToken,
        password,
      });
      return 200;
    } catch (error) {
      console.error(error);
      return error.response.status;
    }
  }

  *markAllNotification(notificationId) {
    try {
      const response = yield api.post(
        `/notification/update-notification-status`,
        {
          notificationId: notificationId ?? "",
          status: "read",
          markAll: notificationId ? false : true,
        }
      );
      const allNotificationMark = response.data;
      console.log("getNotificationList", allNotificationMark);

      return { status: 200, data: allNotificationMark };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *fetchNotifications(userId, page, limit) {
    try {
      const response = yield api.get(
        `/notification/get-notifications?page=${page ?? 1}&limit=${limit ?? 10}`
      );
      return { status: 200, data: response?.data };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *skipTour(userId, pages) {
    try {
      const response = yield api.patch(`/account/skipTour/${userId}`, pages);
      this.isSkipTourGuide = response.data.data.isSkipTourGuide;
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }
}
