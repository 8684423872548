import { Box, IconButton } from '@material-ui/core';
import { useCallback, useMemo, useState } from 'react';

import CheckIcon from '@material-ui/icons/Check';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

export default function ToggleMenu({
  startIcon,
  options,
  selectedIds = [],
  multi = false,
  onChange,
  ...props
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const selectedOptions = useMemo(() => {
    return selectedIds.map((id) => options.find((option) => option.id === id));
  }, [options, selectedIds]);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleMenuItemClick = useCallback(
    (option) => () => {
      if (multi === false) {
        onChange([option]);
        handleClose();
      } else {
        let newOptions;
        if (!option?.id) {
          newOptions = [option];
        } else if (selectedOptions.map(({ id }) => id).includes(option.id)) {
          newOptions = selectedOptions.filter(({ id }) => id !== option.id);
        } else {
          newOptions = [...selectedOptions, option];
        }
        onChange(newOptions);
      }
    },
    [handleClose, multi, onChange, selectedOptions]
  );

  return (
    <Box display="flex" flexWrap="nowrap" {...props}>
      <IconButton color="inherit" onClick={handleClick}>
        {startIcon}
      </IconButton>
    </Box>
  );
}
