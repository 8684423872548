import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getAnalytics } from 'firebase/analytics';
import Cookies from 'js-cookie';

const firebaseConfig = {
  apiKey: "AIzaSyDqUHKI4Gi5uTfM0d4dQgaudfGRveHjCro",
  authDomain: "superkos-db514.firebaseapp.com",
  projectId: "superkos-db514",
  storageBucket: "superkos-db514.firebasestorage.app",
  messagingSenderId: "818300559295",
  appId: "1:818300559295:web:3cb60ae987925699decb0a",
  measurementId: "G-VETYHL9ZG4"
};


const app = initializeApp(firebaseConfig);
if (typeof window !== 'undefined' && window.firebase && window.firebase.analytics) {
  getAnalytics(app);

}


export let messaging = {};
if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
  messaging = typeof window !== 'undefined' ? getMessaging(app) : null;

}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export const requestForToken = async () => {
  if (typeof window !== 'undefined' && 'Notification' in window && 'serviceWorker' in navigator) {
    console.log("Requesting User Permission......");

    // Function to detect Incognito mode
    const isIncognito = await checkIncognitoMode();

    if (isIncognito) {
      console.log("Incognito Mode detected. Notifications may not work.");
      alert("You are in Incognito mode. Push notifications may not be available.");
      return; // Return early, as notifications might not work in Incognito mode.
    }

    // Check if permission has already been denied
    if (Notification.permission === 'denied') {
      console.log("Notifications have already been denied.");
      return;
    }

    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        console.log("Notification permission granted.");

        // Retrieve token from Firebase Cloud Messaging
        const currentToken = await getToken(messaging, {
          usePublicVapidKey: `BJr6NxSh4sv6mVWeI7qpe15dL3z8CI-Vlxg7_c9fSBWLLYt4-nGsoTHj9VZ8oOv3o4w725WyQgPIcLADcdoRLQM`
        });

        if (currentToken) {
          console.log('Client Token: ', currentToken);
          Cookies.set('token', currentToken, { secure: true });
        } else {
          console.log('Failed to generate the registration token.');
        }
      } else {
        console.log("User permission denied.");
      }
    } catch (err) {
      console.log('An error occurred when requesting to receive the token.', err);
    }
  } else {
    console.log("Browser-specific APIs are not available.");
  }
};

// Helper function to detect Incognito mode
const checkIncognitoMode = () => {
  return new Promise((resolve) => {
    const cookieEnabled = navigator.cookieEnabled;
    if (!cookieEnabled) {
      resolve(true); // Likely in incognito mode
    } else {
      resolve(false); // Normal browsing mode
    }
  });
};



