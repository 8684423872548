import React, { useEffect, useState, useContext } from "react";
import {
  Popover,
  Badge,
  Button,
  makeStyles,
  Typography,
  Grid,
  withStyles,
} from "@material-ui/core";
import { NotificationsNoneOutlined } from "@material-ui/icons";
import { observer } from "mobx-react-lite";
import { StoreContext } from "../../store";
import useTranslation from "next-translate/useTranslation";
import socketService, { socket } from "../../helpers/socketio-client";
import ToggleMenu from "../ToggleMenu";
import { useRouter } from "next/router";
import {
  capitalizeFirstLetterOnly,
  formatDateTime,
} from "../../utils/constants";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { color } from "../../styles/color";
const useStyles = makeStyles((theme) => ({
  notificationContainer: {
    padding: theme.spacing(2),
    borderRadius: 8,
    backgroundColor: "#fff",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
    width: "300px",
  },
  notificationItem: {
    padding: theme.spacing(1),
    borderBottom: "1px solid #f0f0f0",
  },
  notificationTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(0.5),
    fontSize: 14,
  },
  notificationMessage: {
    color: "#333333",
    fontSize: 14,
  },
  notificationTimestamp: {
    color: "#666",
    paddingTop: 5,
    fontSize: 12,
  },
  markAllButton: {
    marginTop: "-4%",
    height: 26,
  },
  noNotifications: {
    textAlign: "center",
    padding: theme.spacing(2),
    color: "#888",
  },
  viewAllLink: {
    textAlign: "center",
    color: "#007bff",
    cursor: "pointer",
    marginTop: theme.spacing(2),
  },
}));

const CustomBadge = withStyles(() => ({
  badge: {
    backgroundColor: color.highLightColor, // Custom background color
  },
}))(Badge);
function CustomIdleNotification(props) {
  const router = useRouter();
  const { t } = useTranslation("common");
  const [notificationListData, setNotificationListData] = useState({});
  const [anchorEl, setAnchorEl] = useState(null); // Anchor element for Popover
  const [openPopover, setOpenPopover] = useState(false); // State to manage popover visibility
  const store = useContext(StoreContext);
  const classes = useStyles();
  const [isConnected, setIsConnected] = useState(false);
  const [transport, setTransport] = useState("N/A");

  const handleNotificationClick = (title) => {
    let url = "";

    // Check the title and assign the correct URL
    switch (title) {
      case "SKOS - Invoice Created!":
        //url = 'https://dev.superkos.id/tenants/6777b4610980c70bf23805b0/Penyewa/%2Ftenants';
        break;
      case "SKOS - Rent Reminder":
        // url = 'https://dev.superkos.id/tenants/6777b4610980c70bf23805b0/Penyewa/%2Ftenants';
        break;
      case "SKOS - Last Reminder for Rent!":
        // url = 'https://dev.superkos.id/tenants/6777b4610980c70bf23805b0/Penyewa/%2Ftenants';
        break;
      case "SKOS - Payment Receipt":
        //url = 'https://dev.superkos.id/tenants/6777b4610980c70bf23805b0/Penyewa/%2Ftenants';
        break;
      case "SKOS - Payment Confirmation":
        // url = 'https://dev.superkos.id/tenants/6777b4610980c70bf23805b0/Penyewa/%2Ftenants';
        break;
      case "SKOS - Daily Due Report":
        //  url = 'https://dev.superkos.id/reports';
        break;
      case "SKOS - Weekly Due Report":
        // url = 'https://dev.superkos.id/reports';
        break;
      case "SKOS | WhatsApp Disconnected":
        // url = 'https://dev.superkos.id/properties/67651250bc6402e397363b6b/Properti/%2Fproperties';
        break;
      default:
        console.log("No URL defined for this title");
        break;
    }

    // If a URL was found, navigate to it
    if (url) {
      window.location.href = url;
    }
  };

  useEffect(() => {
    if (socket.connected) {
      onConnect();
    }

    function onConnect() {
      setIsConnected(true);
      setTransport(socket.io.engine.transport.name);

      socket.io.engine.on("upgrade", (transport) => {
        setTransport(transport.name);
      });
    }

    function onDisconnect() {
      setIsConnected(false);
      setTransport("N/A");
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
    };
  }, []);

  useEffect(() => {
    // Request unread count when the component mounts
    socketService.getUnreadCount(store.user._id);

    // Listen for the unread count response
    socketService.onUnreadCount((count) => {
      // The count object has the senderId and the count of unread messages
      setUnreadCount(count);
    });
  }, [store.user._id]);

  useEffect(() => {
    fetchNotificationList();
  }, []);
  //   notification redirection
  const handleRedirect = (notification) => {
    console.log("notification",notification?.type)
  };

  // fetch notification listing
  const fetchNotificationList = async () => {
    try {
      const response = await store.user.fetchNotifications(store.user._id);
      if (response) {
        setNotificationListData(response?.data); // Assuming response.data holds the notifications
      } else {
        store.pushToastMessage({
          message: t("Something went wrong"),
          severity: "error",
        });
      }
    } catch (error) {
      store.pushToastMessage({
        message: t("Something went wrong"),
        severity: "error",
      });
    }
  };

  // read notification
  const readAllNotification = async (notification) => {
    let notificationId = notification?._id;
    if (notificationId) {
      handleRedirect(notification);
    }
    try {
      const response = await store.user.markAllNotification(notificationId);
      if (response && response?.status === 200) {
        if (!notificationId) {
          fetchNotificationList();
          store.pushToastMessage({
            message: t(`${response?.data?.success}`),
            severity: "success",
          });
        } else {
          let index = notificationListData?.notifications?.findIndex(
            (item) => item?._id === notificationId
          );
          let temp = { ...notificationListData };
          temp.notifications[index].status = "read";
          temp.unreadNotificationCount = temp.unreadNotificationCount - 1;
          setNotificationListData(temp);
        }
        // Refresh the notification list after marking them all as read
      } else {
        store.pushToastMessage({
          // message: t('Something went wrong'),
          severity: "error",
        });
      }
    } catch (error) {
      store.pushToastMessage({
        // message: t('Something went wrong'),
        severity: "error",
      });
    }
  };

  const renderNotificationList = () => {
    if (
      notificationListData &&
      notificationListData?.notifications?.length != 0
    ) {
      return (
        <>
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 10,
            }}
          >
            <Grid xs={6} item>
              <Typography className={classes.notificationTitle}>
                Notifications
              </Typography>
            </Grid>
            {notificationListData?.unreadNotificationCount > 0 && (
              <Grid xs={6} style={{ textAlign: "end" }} item>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.markAllButton}
                  onClick={() => readAllNotification()}
                >
                  <Typography style={{ textTransform: "none", fontSize: 13 }}>
                    {capitalizeFirstLetterOnly("read all")}
                  </Typography>
                </Button>
              </Grid>
            )}
          </Grid>

          {notificationListData?.notifications
            ?.slice(0, 5)
            .map((notification, index) => (
              <div
                key={index}
                style={{ cursor: "pointer" }}
                className={classes.notificationItem}
                onClick={() => readAllNotification(notification)}
                // onClick={() => handleNotificationClick(notification.title)} // Trigger redirection on click
              >
                <Grid container>
                  <Grid item xs={11}>
                    <Typography className={classes.notificationTitle}>
                      {notification.title}
                    </Typography>
                  </Grid>
                  {notification?.status !== "read" && (
                    <Grid item xs={1}>
                      <FiberManualRecordIcon
                        style={{ fontSize: 15, color: color.highLightColor }}
                      />
                    </Grid>
                  )}
                </Grid>
                <Typography className={classes.notificationMessage}>
                  {notification.message}
                </Typography>
                <Typography className={classes.notificationTimestamp}>
                  {formatDateTime(notification.timestamp)}
                </Typography>
              </div>
            ))}
          {notificationListData?.totalCount > 5 && (
            <div className={classes.viewAllLink}>
              <Typography
                variant="body2"
                onClick={() => router.push("/notificationsList")}
              >
                {t("View all")}
              </Typography>
            </div>
          )}
        </>
      );
    } else {
      return (
        <Typography variant="body2" className={classes.noNotifications}>
          {t("No notifications available")}
        </Typography>
      );
    }
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget); // Set the anchor for the popover
    setOpenPopover(true); // Open the popover
  };

  const handlePopoverClose = () => {
    setOpenPopover(false); // Close the popover
  };

  return (
    <>
      <CustomBadge
        overlap="rectangular"
        badgeContent={
          notificationListData?.unreadNotificationCount > 0
            ? notificationListData?.unreadNotificationCount
            : 0
        } // Show unread count in badge
        // classes={classes.customBadge}
        onClick={handlePopoverOpen} // Open the popover when clicked
      >
        <NotificationsNoneOutlined />
      </CustomBadge>

      <Popover
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className={classes.notificationContainer}>
          {renderNotificationList()}
        </div>
      </Popover>
    </>
  );
}

const OrganizationNotifications = observer(() => {
  const options = [
    { _id: 1, tenantName: "ali" },
    { _id: 2, tenantName: "akbar" },
  ];

  return (
    <>
      <ToggleMenu
        startIcon={<CustomIdleNotification />}
        options={options}
        onChange={"*"}
      />
    </>
  );
});

export default OrganizationNotifications;
